import React, { lazy, useEffect, useState } from "react";
import LandingScreen from "./Screen/LandingScreen";
import { Routes, Route, Navigate } from "react-router-dom";
import { fetchApiPost } from "./Utils/FetchApi";
import { Apiname } from "./Constant/Apiname";
const LandingOTPScreen = lazy(() => import("./Screen/LandingOTPScreen"));

const LandingRoute = () => {
  const [token, setToken] = useState<string | null>(null);
  const [isLanding, setIsLanding] = useState(false);

  useEffect(() => {
    tokenGenerate();
    setIsLanding(true);
  }, []);

  const tokenGenerate = async () => {
    try {
      let data: any = {
        username: process.env.REACT_APP_UserName,
        password: process.env.REACT_APP_Password,
      };
      const result = await fetchApiPost(null, Apiname.authenticateWeb, data);
      if (result.token) {
        setToken(result.token);
      }
    } catch (error) {
      console.log("jwt token error", error);
    }
  };

  return (
    <>
      <Routes>
        <Route
          path="/landing"
          element={<LandingScreen token={token} isLanding={isLanding} />}
        />
        <Route path="/pin" element={<LandingOTPScreen token={token} />} />
      </Routes>
    </>
  );
};

export default LandingRoute;
