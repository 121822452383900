import React from "react";
import Banner from "./Banner";
import "../Stylesheet/Landingpage.css";

interface Status {
  loading: boolean;
  error: boolean;
  errorMessage: string;
}
type prop = {
  msisdn: string;
  onNextPressed: () => void;
  userInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  status: {
    loading: boolean;
    error: boolean;
    errorMessage: string;
  };
  setStatus: React.Dispatch<React.SetStateAction<Status>>;
  parameter: any;
};

const Landing = ({
  msisdn,
  onNextPressed,
  userInput,
  status,
  setStatus,
  parameter,
}: prop) => {
  return (
    <div className="MainContent">
      <Banner parameter={parameter} />
      <div className="Form">
        <h2>Enter Phone Number</h2>
        <div className="number-field">
          <span>+92</span>
          <div className="input">
            <input
              type="tel"
              value={msisdn}
              placeholder="3xx-xxxx-xxx"
              onChange={(e) => {
                userInput(e);
                setStatus((prevStatus) => ({
                  ...prevStatus,
                  error: false,
                }));
              }}
              max={10}
            />
          </div>
        </div>
        {status.error ? <p className="error">{status.errorMessage}</p> : ""}
        <div
          className="Button"
          onClick={onNextPressed}
          style={{ marginBottom: "10px" }}
        >
          <span>
            {status.loading ? <div className="lds-dual-ring"></div> : "Submit"}
          </span>
        </div>
        <p>*Prepaid charges: Rs. 5+T/Day*</p>
        <p className="policy">
          <span
            onClick={() => {
              window.open("/privacypolicy", "_blank");
            }}
          >
            Privacy Policy
          </span>
          <span className="dot"></span>
          <span
            onClick={() => {
              window.open("/terms", "_blank");
            }}
          >
            Terms & Conditions
          </span>
        </p>
      </div>
    </div>
  );
};

export default Landing;
