import React, { useState, useEffect } from "react";
import { encode as base64_encode } from "base-64";
import Landing from "../components/Landing";
import { Apiname } from "../Constant/Apiname";
import { useNavigate } from "react-router-dom";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import tagManagerEvents from "../Utils/GoogleTagManager";
import TagManager from "react-gtm-module";

interface props {
  token: string | null;
  isLanding: boolean;
}
interface getflowapi {
  status: number;
  result: {
    fastFlow: boolean;

    isSpecialFlow: boolean;

    milliseconds: number;

    msisdn: string;

    otp: string;

    signIn: boolean;
  };

  // data: {
  //   value: string;
  //   otp: string;
  //   time: number;
  //   utm: string;
  //   result: boolean;
  //   fastFlow: boolean;
  //   isSpecialFlow: boolean;
  // };
}
interface Status {
  loading: boolean;
  error: boolean;
  errorMessage: string;
}

const LandingScreen = ({ token, isLanding }: props) => {
  const navigate = useNavigate();
  const [msisdn, setMsisdn] = useState<string>("");
  const [status, setStatus] = useState<Status>({
    loading: false,
    error: false,
    errorMessage: "",
  });
  const [getflowdata, setGetflowdate] = useState<getflowapi>();
  const [utm, setUtm] = useState<string | null>("");
  const [parameter, setParameter] = useState<any>();
  const [subPackage, setSubPackage] = useState("");
  const [heCheck, setHeCheck] = useState(false);

  // useEffect(() => {
  //   if (window.location.protocol === "https:") {
  //     let url = window.location.href;
  //     url = url.replace("https", "http");
  //     window.location.replace(url);
  //   }
  // }, []);

  useEffect(() => {
    if (isLanding) {
      const tagManagerArgs = {
        gtmId: "GTM-5LRQ89V",
      };
      TagManager.initialize(tagManagerArgs);
    }

    if (token !== null) {
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      const utmSource: string | null = searchParams.get("utm_source");
      setUtm(utmSource);
      getFlow(utmSource);
    }
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    setParameter(
      searchParams.get("parameter")
        ? searchParams.get("parameter")?.toLocaleLowerCase()
        : searchParams.get("Parameter")
        ? searchParams.get("Parameter")?.toLocaleLowerCase()
        : ""
    );
  }, [token]);

  const getFlow = async (utmSource: string | null) => {
    try {
      const endPoint = `${Apiname.getflow}?utm_source=${utmSource}`;
      const response = await fetchApiGet(token, endPoint);

      // console.log(response);
      // const response = {
      //   status: 0,
      //   result: {
      //     isSpecialFlow: true,
      //     fastFlow: true,
      //     msisdn: "3045114084",
      //     otp: "1234",
      //     milliseconds: 5000,
      //     signIn: false,
      //     subPackage: "Pre",
      //     network: "Jazz",
      //   },
      // };
      if (response.status === 0) {
        setGetflowdate(response);
        if (response.result.msisdn && response.result.isSpecialFlow === false) {
          tagManagerEvents("He_User", utm);
          setMsisdn(response.result.msisdn);
          setHeCheck(true);
          setSubPackage(response.result.subPackage);
          if (response.result.signIn) {
            let url = window.location.origin;
            let encodedMsisdn = base64_encode(response.result.msisdn);
            url = `${url}/home?user=${encodedMsisdn}`;
            if (url.includes("http:")) {
              url = url.replace("http", "https");
            }
            window.location.replace(url);
          }
        } else if (
          response.result.msisdn &&
          response.result.isSpecialFlow &&
          response.result.otp
        ) {
          const data = {
            msisdn: response.result.msisdn,
            specialFlow: response.result.isSpecialFlow,
            fastFlow: response.result.fastFlow,
            otp: response.result.otp,
            time: response.result.milliseconds,
            utm: utmSource,
            subPackage: response.result.subPackage,
            parameter: parameter,
          };
          setMsisdn(response.result.msisdn);
          navigate("/pin", { state: data });
        } else {
          tagManagerEvents("Wifi_User", utmSource);
        }
      }
    } catch (error) {
      console.log("error ::", error);
    }
  };

  const userInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (!newValue || (newValue.length <= 10 && newValue.match(/^3\d{0,9}$/))) {
      tagManagerEvents("Msisdn_Enter", utm);
      setMsisdn(event.target.value);
    }
  };
  const onNextPressed = () => {
    if (msisdn.length === 10) {
      tagManagerEvents("Get_Otp_Button_Click", utm);
      if (subPackage) {
        setStatus((prevStatus) => ({ ...prevStatus, loading: true }));
        sendOtp(subPackage);
      } else {
        checkOperator();
      }
    } else {
      setStatus((prevStatus) => ({
        ...prevStatus,
        errorMessage: "Please enter 10 digit mobile number",
      }));
    }
  };

  const checkOperator = async () => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, loading: true }));
      const number = Apiname.checkoperator + msisdn;
      const result = await fetchApiGet(token, number);
      if (result.status === 0) {
        let _network = result.network.toLowerCase();
        if (_network === "others" || _network === "other") {
          setStatus((prevStatus) => ({
            ...prevStatus,
            loading: false,
            error: true,
            errorMessage:
              "Service is only available for Jazz customers, kindly enter a working Jazz number ",
          }));
        } else {
          setStatus((prevStatus) => ({ ...prevStatus, error: false }));
          sendOtp(result.package);
        }
      } else {
        setStatus((prevStatus) => ({
          ...prevStatus,
          loading: false,
          error: true,
          errorMessage:
            "Service is only available for Jazz customers, kindly enter a working Jazz number ",
        }));
      }
    } catch (error) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        loading: false,
        error: true,
        errorMessage:
          "Service is only available for Jazz customers, kindly enter a working Jazz number ",
      }));
    }
  };

  const sendOtp = async (_subPackage: string) => {
    try {
      const data = {
        msisdn: msisdn,
      };
      const result = await fetchApiPost(token, Apiname.sendotp, data);
      if (result.status === 0 && result.result.toLowerCase() === "success") {
        const data = {
          msisdn: msisdn,
          otp: result.otp,
          time: getflowdata?.result.milliseconds,
          isUserActive: result.exists,
          isSpecialFlow: getflowdata?.result.isSpecialFlow,
          fastFlow: getflowdata?.result.fastFlow,
          utm: utm,
          parameter: parameter,
          subPackage: _subPackage,
          heCheck: heCheck,
        };
        setStatus((prevStatus) => ({ ...prevStatus, loading: false }));
        navigate("/pin", {
          state: data,
        });
      } else {
        setStatus((prevStatus) => ({
          ...prevStatus,
          loading: false,
          errorMessage: "Unable to send OTP, Please try again",
        }));
      }
    } catch (error) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        loading: false,
        errorMessage: "Unable to send OTP, Please try again",
      }));
    }
  };

  return (
    <>
      <>
        <Landing
          msisdn={msisdn}
          onNextPressed={onNextPressed}
          userInput={userInput}
          status={status}
          setStatus={setStatus}
          parameter={parameter}
        />
      </>
    </>
  );
};

export default LandingScreen;
