import React, { useState, useEffect } from "react";
import logo from "../assets/af37322245da447e737b.webp";
import img from "../assets/Web.webp";
import img2 from "../assets/web2.webp";
import img3 from "../assets/bannerpic 3.webp";

// import background from "../assets/background.webp";
type Props = {
  parameter: any;
};

const Banner = (props: Props) => {
  return (
    <div className="Banner">
      <div className="img">
        <div className="logo">
          <img src={logo} width={120} height={105} alt="logo" />
        </div>
        <div>
          {props.parameter?.toLocaleLowerCase() === "winphone" ? (
            <>
              <img src={img3} width={340} height={320} alt="main-image" />
            </>
          ) : (
            <>
              {props.parameter?.toLocaleLowerCase() === "earning" ? (
                <>
                  <img src={img2} width={340} height={320} alt="main-image" />
                </>
              ) : (
                <>
                  <img src={img} width={340} height={320} alt="main-image" />
                </>
              )}
            </>
          )}
        </div>
      </div>
      {/* <div className="background-img">
        <img src={background} alt="" />
      </div> */}
    </div>
  );
};
export default Banner;
